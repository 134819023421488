<template>
  <!--
    The view for the IncompleteTemplateOverview-component.
    Used to display templates that need more translations to be complete.
  -->
  <Portlet
    class="incompleteTemplateView"
    title="Incomplete Templates"
    icon="tasks"
  >
    <IncompleteTemplateOverview />
  </Portlet>
</template>

<script>
export default {
  name: 'IncompleteTemplateView',
  components: {
    IncompleteTemplateOverview: () => import('@/components/SmsManagement/IncompleteTemplateOverview.vue')
  },
  metaInfo () {
    return {
      title: this.$t('smsManagement')
    }
  }
}
</script>
